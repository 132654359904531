var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5 text-din-regular",attrs:{"lg":_vm.whichPage === 'listings' || _vm.whichPage === 'list_event' ? 12 : 4}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Create An Account ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" To register for an account, please fill up the form below. ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Account Type","label-for":"register-account-type"}},[_c('validation-provider',{attrs:{"name":"Account Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"register-account-type","name":"register-account-type","placeholder":"Choose your account type","options":_vm.account_types,"state":errors.length > 0 ? false : null},model:{value:(_vm.account_type),callback:function ($$v) {_vm.account_type=$$v},expression:"account_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:_vm.showEmployerList ? '': 'd-none',attrs:{"label":"Company Name(s)","label-for":"register-account-company"}},[(_vm.showEmployerList)?_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CompanySearch',{attrs:{"id":"register-account-company","name":"register-account-company","multiple":true,"state":errors.length > 0 ? false : null},on:{"company-selected":_vm.selectedCompanies},model:{value:(_vm.selected_companies),callback:function ($$v) {_vm.selected_companies=$$v},expression:"selected_companies"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2255345140)}):_vm._e()],1),_c('b-form-group',{attrs:{"label":_vm.name_holders,"label-for":"register-fullname"}},[_c('validation-provider',{attrs:{"name":_vm.name_holders,"rules":"required|alpha-spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-fullname","name":"register-fullname","state":errors.length > 0 ? false : null,"placeholder":_vm.name_holders},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email Address","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"user@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","autocomplete":"new-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.userPassword),callback:function ($$v) {_vm.userPassword=$$v},expression:"userPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-confirm-password","label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-confirm-password","autocomplete":"new-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-confirm-password","placeholder":"············"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-terms-policy","name":"checkbox-1"},model:{value:(_vm.isTermsConditionAccepted),callback:function ($$v) {_vm.isTermsConditionAccepted=$$v},expression:"isTermsConditionAccepted"}},[(false)?_c('span',[_vm._v(" I agree to the "),_c('b-link',{on:{"click":function($event){_vm.pop_terms = true}}},[_vm._v("Terms of Use")]),_vm._v(" and the "),_c('b-link',{on:{"click":function($event){_vm.pop_priv = true}}},[_vm._v("Privacy Statement")])],1):_c('span',[_vm._v(" I agree to the "),_c('b-link',{attrs:{"href":"https://designsingapore.org/terms-of-use/","target":"_blank","rel":"nofollow"}},[_vm._v(" Terms of Use ")]),_c('span',[_vm._v("and the ")]),_c('b-link',{attrs:{"href":"https://designsingapore.org/privacy-statement/","target":"_blank","rel":"nofollow"}},[_vm._v("Privacy Statement")])],1)])],1),(_vm.isLoading)?_c('SpinnerBtn'):_c('b-button',{staticClass:"text-din-black",attrs:{"variant":"primary","block":"","disabled":!_vm.isTermsConditionAccepted ||
              !_vm.userEmail.length > 4 ||
              !_vm.userPassword.length > 7,"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_vm._v(" Sign up ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1),_c('b-modal',{attrs:{"centered":"","hide-footer":"","title":"Terms and Conditions","ok-title":"I have read the Terms and Conditions","size":"lg","scrollable":"","ok-only":""},model:{value:(_vm.pop_terms),callback:function ($$v) {_vm.pop_terms=$$v},expression:"pop_terms"}},[_c('Terms')],1),_c('b-modal',{attrs:{"centered":"","hide-footer":"","title":"Privacy Statement","ok-title":"I have read Privacy Statement","size":"lg","scrollable":"","ok-only":""},model:{value:(_vm.pop_priv),callback:function ($$v) {_vm.pop_priv=$$v},expression:"pop_priv"}},[_c('Privacy')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }